import React from "react"
import {graphql} from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../layouts/default"
import SEO from "../components/seo"
import Project from "../components/project"
import Arrow from "../components/arrow"
import Logo from "../components/logo"
import Thumbnail from "../images/thumbnail.jpg" 

const Homepage = ({ data }) => {

  const { projects } = data.craft

  return (

    <Layout>
      <SEO title="Branding, Print and Digital Agency based in Manchester &amp; Melbourne." thumbnail={Thumbnail} />
      <Helmet bodyAttributes={{ class: "s-home" }} />
      <Logo />

      <div className="u-wrapper">
        <div className="u-wrapper__inner u-wrapper__inner--right">

          <section className="o-section u-font-large">
              <p>We transform brands, grow businesses and tell stories that deepen customer engagements. Based in Manchester & Melbourne, our roots lie in the craft of branding, print and digital across all platforms.</p>
              <p>Experience tells us that the best solutions are achieved with close collaboration with our clients. We are and will remain a small, approachable, independent collaborative creative consultancy.</p>
          </section>
      
          <section className="o-section" id="projects">
              <h2 className="u-heading u-heading--pull-left u-heading--reverse">Featured projects</h2>
              <div className="c-projects-list">
                {/* {projects.map(project => (
                  <Project
                    key={project.id}
                    title={project.title}
                    slug={project.slug}  
                    thumbnail={project.thumbnail.map(item => (item.url))}
                  >
                  </Project>
                ))} */}
                  <div className="c-projects-list__col">
                    {projects.slice(0,3).map(project => (
                      <Project
                        key={project.id}
                        title={project.title}
                        slug={project.slug}  
                        thumbnail={project.thumbnail}
                      >
                      </Project>
                    ))}
                  </div>
                  <div className="c-projects-list__col">
                    {projects.slice(3,6).map(project => (
                      <Project
                        key={project.id}
                        title={project.title}
                        slug={project.slug}
                        thumbnail={project.thumbnail}
                      >
                      </Project>
                    ))}
                  </div>
              </div>
          </section>
      
          <section className="o-section c-contacts">
            <h2 className="u-heading u-heading--pull-left u-heading--reverse u-heading--with-arrow">Get in touch <Arrow class="c-arrow c-arrow--reverse" /></h2>
            <div className="c-contact">
              <div className="c-contact__location">
                <h3>Manchester</h3>
                <a href="mailto:alex@warehousesix.com">alex@warehousesix.com</a>
              </div>
              <div className="c-contact__location">
                <h3>Melbourne</h3>
                <a href="mailto:darren@warehousesix.com">darren@warehousesix.com</a>
              </div>
            </div>
          </section>

        </div>
      </div>
  
    </Layout>
  
    )

}

export const query = graphql`
  {
    craft {
      projects: entries(section: "projects") {
        ... on Craft_projects_projects_Entry {
          id
          title
          slug
          thumbnail {
            id
            url
            title
            ... on Craft_images_Asset {
              cloudinaryPublicId
            }
          }
        }
      }
    }
  }
`;

export default Homepage
