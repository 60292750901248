import { Link } from "gatsby"
import React from "react"
import { Reveal, Tween } from 'react-gsap';
import {Image, Transformation} from 'cloudinary-react';

const Project = (props) => {

  Project.props = {
    title: props.title.isRequired,
    slug: props.slug.isRequired,
  }

  return (
    <Reveal>
       <Tween
        from={{ y: '30px', opacity: '0' }}
        to={{ y: '0px', opacity: '1' }}
        duration={.5}
      >
        <article className="c-projects-list__entry">
          <Link to={props.slug}>
            {props.thumbnail.map(image => (
              <figure key={image.id}>
                <Image
                    cloudName="warehousesix"
                    publicId={image.cloudinaryPublicId}  
                    dpr="auto"
                    responsive
                    width="auto"
                    alt={props.title}
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                </Image>
              </figure>
            ))}
            <h3>{props.title}</h3>
          </Link>
        </article>
      </Tween>
    </Reveal>
  )
  
}

export default Project